.carousel-indicators {
  //display: inline-flex;
  display: none;
  background-color: $color-white;
  margin: 0;
  left: 50%;
  bottom: 10px;
  right: auto;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  height: 24px;
  border-radius: 12px;
  padding: 8px 10px;

  li {
    background-color: $color-grey-600;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    border: none;
    margin-left: 0;
    &.active {
      background-color: $color-main-400;
    }

    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}

.carousel-control-prev {
  background-color: $color-white;
}

.carousel-control-next,
.carousel-control-prev {
  top: initial;
  width: 48px;
  height: 48px;
  opacity: 1;
  border-radius: 6px;
}

.carousel-control-next-icon {
  -webkit-animation: progressBar 7s ease-out infinite;
  animation: progressBar 7s ease-out infinite;
  background: -webkit-gradient(linear, left top, right top, color-stop(50%, $color-white), color-stop(50%, $color-grey-300));
  background: -o-linear-gradient(left, $color-white 50%, $color-grey-300 50%);
  background: linear-gradient(to right, $color-white 50%, $color-grey-300 50%);
  background-size: 200% 100%;
  background-position: right bottom;
}
.carousel:hover {
  .carousel-control-next-icon {
    -webkit-animation: none;
    animation: none;
  }
}

@-webkit-keyframes progressBar {
  100% {
    background-position: left bottom;
  }
}

@keyframes progressBar {
  100% {
    background-position: left bottom;
  }
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  border-radius: 6px;
  height: 48px;
  width: 48px;
  cursor: pointer;
  position: relative;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 24px;
    height: 24px;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-image: url("/assets/img/arrow-left.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.carousel-control-next-icon:after {
  background-image: url("/assets/img/arrow-right.svg");
}

.carousel-caption {
  color: $color-main-400;
  width: 100%;
  top: 0;
  left: 0;
  padding: 0;
  text-align: left;
  height: 476px;
}

.carousel.slide {
  outline: none;
  min-height: 476px;
  height: 100%;

  .carousel-inner {
    overflow: visible;
  }
}

@media (max-width: 481px) {
  .carousel-indicators {
    display: block;
  }

  .carousel-control-next {
    right: 16px;
  }

  .carousel-control-prev {
    left: 16px;
  }
}
