.page {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
  margin-top: 44px;
  padding: 0 4px;

  &-item {
    width: 100%;
    margin-bottom: 120px;
    z-index: 1;
    border-radius: 16px;

    &.card-bottom {
      margin-top: -136px;

      &.action-card {
        padding: 0 24px;
      }
    }

    & + .action-card-bottom {
      //margin-top: -80px;
      margin-top: 60px;
    }

    .button-list {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;

      .button {
        &:not(:last-child) {
          margin-right: 24px;
        }
      }
    }

    &.info-top {
      padding: 0 20px;
    }

    &.page-action-card {
      padding: 0 20px;
    }

    &.action-card-bottom {
      padding: 0 20px;
    }

    &.bg-white {
      background-color: $color-white;
    }

    &.bg-grey {
      background: #f4f5f9;
      border-radius: 16px;
      box-shadow: 0 20px 60px 0 rgba(10, 54, 164, 0.08);
      border-top: 2px solid $color-white;
    }

    &.bg-dark {
      background-color: $color-main !important;

     & > .page-text-title {
        color: $color-white;
      }
    }
  }

  &-text {
    max-width: 776px;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 1;

    &-title {
      font-size: 40px;
      letter-spacing: -0.5px;
      line-height: 48px;
      color: $color-main;
      margin: 16px 0;
    }

    &-descr {
      font-size: 20px;
      letter-spacing: -0.29px;
      line-height: 32px;
      font-weight: 400;
      color: $color-main-300;
    }

    &-top {
      .page-text-descr {
        font-size: 20px;
        letter-spacing: -0.29px;
        line-height: 32px;
        margin-bottom: 0;
      }

      .page-text-title {
        font-size: 48px;
        font-weight: 500;
        letter-spacing: -0.8px;
        line-height: 56px;
        margin-bottom: 24px;
        color: #202431;
      }
    }
  }

  &-content-container {
    position: relative;

    &-item {
      max-width: 976px;
      width: 100%;
      margin-bottom: 40px;

      &.w-full {
        max-width: 100%;
      }

      &.card-bottom {
        position: absolute;
        bottom: 0;
        margin-bottom: 0;
        -webkit-transform: translateY(66%);
        -ms-transform: translateY(66%);
        transform: translateY(66%);
      }

      &.text-content {
        text-align: center;
        max-width: 776px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;

        .text-content-title {
          font-size: 40px;
          font-weight: 500;
          margin: 16px 0;
          line-height: 48px;
          letter-spacing: -0.5px;
        }

        h4.text-content-title {
          font-size: 28px;
          line-height: 40px;
        }

        .text-content-descr {
          color: $color-main-300;
          font-size: 20px;
          font-weight: normal;
          letter-spacing: -0.29px;
          line-height: 32px;
          text-align: center;
        }

        .text-content-rectangle {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
        }
      }

      .item-info-rectangle {
        margin-top: 24px;
      }

      &.anchor-list, &.action-card-top, &.info-card-top {
        position: absolute;
        top: 0;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        max-width: 1216px;
        padding: 0 20px;
      }
    }

    &.img-top {
      margin-top: 172px;
    }

    &.card-bottom {
      margin-bottom: 0 !important;
    }

    &.anch-top {
      margin-top: 106px;
    }

    &.action-card-top {
      margin-top: 132px;
    }
  }

  &-gradient {
    &-wrapper {
      width: 100vw;
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      overflow: hidden;
      z-index: -1;
      //overflow: visible;
      //z-index: 999;
    }

    &-container {
      position: absolute;
      top: 0;
      left: 50%;
      height: 6766px;
      max-width: 1440px;
      width: 100%;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      max-height: calc(100% + 1412px);
      z-index: -1;
    }
  }

  &-gradient--1 {
    position: absolute;
    //left: -600px;
    //top: -1331px;
    left: -127px;
    top: -1545px;
    height: 2348px;
    width: 1738px;
    z-index: auto;


    .gradient {
      position: absolute;
      left: 0;
      width: 100%;
      height: 60%;
      top: 467px;
      background: linear-gradient(-135deg, rgb(249, 249, 252) 0%, rgb(238, 239, 244) 100%);
      -webkit-transform: skew(0, $page-gradient-deg);
      -ms-transform: skew(0, $page-gradient-deg);
      transform: skew(0, $page-gradient-deg);

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: -o-linear-gradient(135deg, rgb(249, 249, 252) 0%, rgb(238, 239, 244) 100%);
        background: linear-gradient(135deg, rgb(249, 249, 252) 0%, rgb(238, 239, 244) 100%);
        -webkit-transform: translate(-100%, 0);
        -ms-transform: translate(-100%, 0);
        transform: translate(-100%, 0);
      }
    }
  }

  &-gradient--2, &-gradient--3, &-gradient--5 {
    position: absolute;
    top: 747px;
    right: -197px;
    height: 991px;
    width: 734px;

    .gradient {
      position: absolute;
      top: 232px;
      left: 0;
      width: 100%;
      height: 53%;
      background: linear-gradient(-135deg, rgb(249, 249, 252) 0%, rgb(238, 239, 244) 100%);
      -webkit-transform: skew(0, $page-gradient-deg);
      -ms-transform: skew(0, $page-gradient-deg);
      transform: skew(0, $page-gradient-deg);

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(135deg, rgb(249, 249, 252) 0%, rgb(238, 239, 244) 100%);
        -webkit-transform: translate(100%, 0);
        -ms-transform: translate(100%, 0);
        transform: translate(100%, 0);
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(135deg, rgb(249, 249, 252) 0%, rgb(238, 239, 244) 100%);
        -webkit-transform: translate(-100%, 0);
        -ms-transform: translate(-100%, 0);
        transform: translate(-100%, 0);
      }
    }
  }

  &-gradient--3 {
    right: auto;
    left: -162px;
    top: 1535px;

    .gradient {
      &:after {
        display: none;
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(135deg, rgb(249, 249, 252) 0%, rgb(238, 239, 244) 100%);
        -webkit-transform: translate(-100%, 0);
        -ms-transform: translate(-100%, 0);
        transform: translate(-100%, 0);
      }
    }
  }

  &-gradient--5 {
    right: -480px;
    top: 2393px;

    .gradient:before {
      background: linear-gradient(90deg, rgb(249, 249, 252) 0%, rgb(243, 244, 248) 100%);
    }
  }

  &-gradient--4 {
    position: absolute;
    top: 4045px;
    left: -38px;
    height: 991px;
    width: 734px;

    .gradient {
      position: absolute;
      top: 203px;
      left: 0;
      width: 100%;
      height: 60%;
      background: -o-linear-gradient(212deg, rgba(255,255,255,0.48) 0%, #F1F2F7 100%);
      background: linear-gradient(238deg, rgba(255,255,255,0.48) 0%, #F1F2F7 100%);
      -webkit-transform: translateY(-6px) skew(0, $page-gradient-deg);
      -ms-transform: translateY(-6px) skew(0, $page-gradient-deg);
      transform: translateY(-6px) skew(0, $page-gradient-deg);

      &:after {
        //content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: -o-linear-gradient(328deg, rgba(255,255,255,0.48) 0%, #F1F2F7 100%);
        background: linear-gradient(-238deg, rgba(255,255,255,0.48) 0%, #F1F2F7 100%);
        -webkit-transform: translate(100%, 0);
        -ms-transform: translate(100%, 0);
        transform: translate(100%, 0);
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: -o-linear-gradient(328deg, rgba(255,255,255,0.48) 0%, #F1F2F7 100%);
        background: linear-gradient(-238deg, rgba(255,255,255,0.48) 0%, #F1F2F7 100%);
        -webkit-transform: translate(-100%, 0);
        -ms-transform: translate(-100%, 0);
        transform: translate(-100%, 0);
      }
    }

    &-1 {
      top: 8436px;
      left: -469px;
    }

  }

  &-slider,
  &-info-card  {
    padding: 0 20px;
  }
}

@media (max-width: 961px) {
  .page {
    padding-top: 72px !important;

    &-item {
      margin-bottom: 80px;

      &.page-action-card {
        margin-bottom: 60px;
        padding: 0 20px;

        &-bottom {
          margin-bottom: 40px;
        }
      }

      &.page-anchor {
        padding: 0 20px;
        margin-bottom: 16px;
      }

      &.text-note {
        margin-bottom: 40px;
        padding: 0 20px;
      }

      &.base-cards-app {
        padding: 0 20px;
      }

      &.action-card-bottom {
        transform: translateY(calc(-50% + 80px));
        margin-bottom: 40px;

        & + .page-item {
          margin-top: -40px;
        }
      }

      &.info-top {
        margin-bottom: 0 !important;
      }

      &.card-bottom {
        &.action-card {
          padding: 0 60px;
        }
      }
    }

    &-text {
      max-width: 576px;

      .page-text-title {
        font-size: 32px;
        letter-spacing: -0.46px;
        line-height: 40px !important;
      }

      .page-text-descr {
        font-size: 20px;
        line-height: 32px;
      }

      &-top {
        .page-text-title {
          font-size: 40px;
          letter-spacing: -0.57px;
          margin-bottom: 12px;
          line-height: 52px !important;
        }

        .page-text-descr {
          font-size: 24px;
          line-height: 40px;
        }
      }
    }

    &-content-container {
      &-item {
        padding: 0 60px;
        margin-bottom: 24px;

        //&.w-full {
        //  max-width: 600px;
        //}

        &.action-card-top {
          max-width: 100%;
          padding: 0 20px;
        }

        &.card-bottom {
          margin-top: -172px;
          -webkit-transform: translateY(50%);
          -ms-transform: translateY(50%);
          transform: translateY(50%);
        }

        &.text-content {
          max-width: 600px;

          .text-content-title {
            font-size: 32px;
            font-weight: 500;
            margin: 16px 0;
            margin-bottom: 0 !important;
            line-height: 40px !important;
            letter-spacing: -0.46px !important;
          }

          h4.text-content-title {
            font-size: 28px;
            line-height: 40px;
          }

          .text-content-descr {
            color: $color-main-300;
            font-size: 20px;
            font-weight: normal;
            letter-spacing: -0.29px;
            line-height: 32px;
            text-align: center;
            margin-top: 12px;
          }

          .text-content-rectangle {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
          }
        }

        &.action-card {
          margin-top: 16px;
        }

        &.anchor-list {
          padding: 0 20px;
        }
      }

      &.action-card-top {
        margin-top: 126px;

        &-size-y {
          margin-top: 166px;
        }
      }
    }

    &-gradient--2 {
      display: none;
    }

    &-gradient--5 .gradient {
      background: linear-gradient(-135deg, #f9f9fc 0%, #f3f4f8 100%);
    }
  }
}

@media (max-width: 751px) {
  .page {
    &-content-container {
      &-item {
        padding: 0;
        max-width: 600px;
      }

      &.anch-top:not(.anch-top-size-y) {
        margin-top: 196px;
      }
    }

    &-item.card-bottom.action-card {
      padding: 0 20px;
      max-width: 600px;
    }
  }
}

@media (max-width: 575px) {
  .page {
    &-content-container {
      &.anch-top {
        margin-top: 196px !important;

        &-size-y {
          margin-top: 106px !important;
        }
      }
    }
  }
}

@media (max-width: 481px) {
  .page {
    margin-top: 0 !important;

    &-item {
      &.info-top {
        margin-bottom: 0 !important;
        padding: 0 !important;
      }

      &.mb-0 {
        margin-bottom: 0 !important;
      }

      &.page-action-card {
        margin-bottom: 40px;
        padding: 0 4px;
      }

      &.base-cards-app {
        padding: 0 8px;
      }

      .button-list {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;

        .button {
          &:first-child {
            margin-bottom: 16px;
            margin-right: 0;
          }
        }
      }

      &.action-card-bottom {
        padding: 0 4px;

        & + .page-item {
          margin-top: -80px;
        }
      }
    }

    &-text {
      padding: 0 16px;

      .page-text-title {
        font-size: 28px;
        line-height: 38px !important;
        margin-bottom: 8px;
        letter-spacing: -0.4px;
        word-break: break-word;
        hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
      }

      .page-text-descr {
        font-size: 18px;
        line-height: 30px;
        letter-spacing: -0.26px;
      }

      &-top {
        .page-text-title {
          font-size: 32px;
          letter-spacing: -0.46px;
          margin-bottom: 16px;
          line-height: 40px;
        }

        .page-text-descr {
          font-size: 20px;
          line-height: 32px;
          letter-spacing: -0.29px;
        }
      }
    }

    &-content-container {
      &-item {
        margin-bottom: 24px !important;

        &.card-bottom {
          margin-top: -170px;
        }

        &.text-content {
          margin-bottom: 24px;

          .text-content-title {
            font-size: 28px;
            line-height: 36px !important;
            margin-top: 16px;
            margin-bottom: 0 !important;
            letter-spacing: -0.4px !important;
          }

          .text-content-rectangle {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
          }
        }

        .item-info-rectangle {
          margin-top: 16px;
        }

        &:last-child {
          margin-bottom: 0 !important;
        }

        &.anchor-list {
          padding: 0 4px !important;
        }
      }

      &.img-top {
        margin-top: 92px !important;
      }

      &.anch-top {
        margin-top: 196px !important;

        &-size-y {
          margin-top: 106px !important;
        }
      }

      &.action-card-top {
        margin-top: 132px !important;

        &-size-y {
          margin-top: 182px !important;
        }
      }
    }

    &-slider,
    &-info-card  {
      padding: 0;
    }
  }
}
