@mixin containerWithBorderGradient ($main-bg-color, $border-gradient, $border-radius) {
  background: linear-gradient(to top, $main-bg-color, $border-gradient);
  z-index: 1;
  border-radius: $border-radius;

  & > * {
    z-index: 3;
  }

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border-radius: $border-radius;
    background-color: $main-bg-color;
    z-index: -1;
  }
}


@mixin iconContainerGradient($color) {
  @if $color == violet {
    background: linear-gradient(-135deg, rgb(131, 80, 239) 0%, rgb(114, 21, 227) 100%);
    box-shadow: inset 0 1px 0 0 rgb(162, 150, 245),
    0 0 2px 0 rgba(48, 54, 74, 0.1),
    0 3px 4px 0 rgba(61, 0, 135, 0.2);
} @else if $color == red {
    background: linear-gradient(-135deg, rgb(239, 83, 80) 0%, rgb(227, 25, 21) 100%);
    box-shadow: inset 0 1px 0 0 rgb(245, 152, 150),
    0 0 4px 0 rgba(48, 54, 74, 0.1),
    0 5px 8px 0 rgba(123, 3, 0, 0.2);
  } @else if $color == yellow {
    background: linear-gradient(-135deg, rgb(255, 183, 77) 0%, rgb(255, 154, 6) 100%);
    box-shadow: inset 0 1px 0 0 rgb(255, 214, 153),
    0 0 4px 0 rgba(48, 54, 74, 0.1),
    0 5px 8px 0 rgba(120, 71, 0, 0.2);
  } @else if $color == green {
    background: linear-gradient(-135deg, rgb(107, 205, 80) 0%, rgb(73, 166, 47) 100%);
    box-shadow: inset 0 1px 0 0 rgb(157, 222, 139),
    0 0 4px 0 rgba(48, 54, 74, 0.1),
    0 5px 8px 0 rgba(28, 128, 0, 0.2);
  } @else if $color == orange {
    background: linear-gradient(-135deg, rgb(239, 119, 72) 0%, rgb(227, 86, 21) 100%);
    box-shadow: inset 0 1px 0 0 rgb(245, 178, 150),
    0 0 4px 0 rgba(48, 54, 74, 0.1),
    0 5px 8px 0 rgba(128, 40, 0, 0.2);
  } @else if $color == purple {
    background: linear-gradient(-135deg, rgb(226, 69, 215) 0%, rgb(212, 22, 212) 100%);
    box-shadow: inset 0 1px 0 0 rgb(245, 150, 232),
    0 0 4px 0 rgba(48, 54, 74, 0.1),
    0 5px 8px 0 rgba(128, 0, 124, 0.2);
  } @else if $color == brown {
    background: linear-gradient(-135deg, rgb(119, 81, 72) 0%, rgb(87, 52, 43) 100%);
    box-shadow: inset 0 1px 0 0 rgb(162, 130, 121),
    0 0 4px 0 rgba(48, 54, 74, 0.1),
    0 5px 8px 0 rgba(41, 16, 0, 0.2);
  } @else if $color == sky {
    background: linear-gradient(-135deg, rgb(64, 185, 231) 0%, rgb(0, 158, 198) 100%);
    box-shadow: inset 0 1px 0 0 rgb(129, 225, 255),
    0 0 4px 0 rgba(48, 54, 74, 0.1),
    0 5px 8px 0 rgba(0, 71, 128, 0.2);
  } @else if $color == mint {
    background: linear-gradient(-135deg, rgb(53, 228, 177) 0%, rgb(0, 207, 159) 100%);
    box-shadow: inset 0 1px 0 0 rgb(134, 255, 215),
    0 0 4px 0 rgba(48, 54, 74, 0.1),
    0 5px 8px 0 rgba(0, 128, 64, 0.2);
  }
}

@mixin displaySlowText {
  &:not(.device-375) {
    display: none !important;
  }

  &.device-375 {
    display: block !important;
  }
}

@mixin labelColor($color) {
  @if $color == orange {
    background-color: #fdede6;

    .title {
      color: #ef7748;
    }
  } @else if $color == violet {
    background-color: #e9e6ff;

    .title {
      color: #8350ef;
    }
  } @else if $color == mint {
    background-color: #eafff8;

    .title {
      color: #35e4b1;
    }
  } @else if $color == sky {
    background-color: #e6f9ff;

    .title {
      color: #40b9e7;
    }
  } @else if $color == brown {
    background-color: rgba(#eee0d1, .5);

    .title {
      color: #775148;
    }
  } @else if $color == purple {
    background-color: #ebdeff;

    .title {
      color: #9653ff;
    }
  } @else if $color == green {
    background-color: #daf2d3;

    .title {
      color: #6bcd50;
    }
  } @else if $color == red {
    background-color: #ffe3e7;

    .title {
      color: #f6576e;
    }
  } @else if $color == yellow {
    background-color: #fff1dc;

    .title {
      color: #ffb74d;
    }
  }

}

