.tooltip {
  top: 9px !important;
  left: 15px !important;
  padding: 0;
  opacity: 1;
  z-index: 1000;

  .arrow {
    &:before {
      border: none;
      background-image: url("/assets/img/baloon-pointer-dark.svg");
      background-repeat: no-repeat;
      background-size: contain;
      width: 30px;
      height: 16px;
      -webkit-transform: rotate(86deg);
      -ms-transform: rotate(86deg);
      transform: rotate(86deg);
      right: -4px;
      top: -3px;
    }
  }

  &-inner {
    border-radius: 24px;
    padding: 20px 16px;
    background-color: $color-grey-dark;
    z-index: 1000;
  }

  &.show {
    opacity: 1;
  }

  &.bs-tooltip-left {
    left: -20px !important;

    .arrow {
      right: 3px;

      &:before {
        -webkit-transform: rotate(96deg) scale(1, -1);
        -ms-transform: rotate(96deg) scale(1, -1);
        transform: rotate(96deg) scale(1, -1);
      }
    }
  }
}
