@font-face {
	font-family: "icons";
	src: url('/assets/fonts/icons/icons.eot');
	src: url('/assets/fonts/icons/icons.eot?#iefix') format('eot'),
	url('/assets/fonts/icons/icons.woff2') format('woff2'),
	url('/assets/fonts/icons/icons.woff') format('woff'),
	url('/assets/fonts/icons/icons.ttf') format('truetype'),
	url('/assets/fonts/icons/icons.svg#icons') format('svg');
  font-display: swap;
}

@mixin icon-styles {
	font-family: "icons";
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	// speak: none; // only necessary if not using the private unicode range (firstGlyph option)
	text-decoration: none;
	text-transform: none;
}

%icon {
  @include icon-styles;
}

@function icon-char($filename, $important:null) {
  $char: "";

  @if $filename == abc-analysis {
    $char: "\E001" $important;
  }
  @if $filename == accountant-report {
    $char: "\E002" $important;
  }
  @if $filename == alert-circle {
    $char: "\E003" $important;
  }
  @if $filename == alert-triangle-divided {
    $char: "\E004" $important;
  }
  @if $filename == alert-triangle {
    $char: "\E005" $important;
  }
  @if $filename == archive {
    $char: "\E006" $important;
  }
  @if $filename == arrow-circle-down {
    $char: "\E007" $important;
  }
  @if $filename == arrow-circle-left {
    $char: "\E008" $important;
  }
  @if $filename == arrow-circle-right {
    $char: "\E009" $important;
  }
  @if $filename == arrow-circle-up {
    $char: "\E00A" $important;
  }
  @if $filename == arrow-circle {
    $char: "\E00B" $important;
  }
  @if $filename == arrow-down {
    $char: "\E00C" $important;
  }
  @if $filename == arrow-filled-down {
    $char: "\E00D" $important;
  }
  @if $filename == arrow-filled-left {
    $char: "\E00E" $important;
  }
  @if $filename == arrow-filled-right {
    $char: "\E00F" $important;
  }
  @if $filename == arrow-filled-up {
    $char: "\E010" $important;
  }
  @if $filename == arrow-left {
    $char: "\E011" $important;
  }
  @if $filename == arrow-micro-down {
    $char: "\E012" $important;
  }
  @if $filename == arrow-micro-up {
    $char: "\E013" $important;
  }
  @if $filename == arrow-outlined-down {
    $char: "\E014" $important;
  }
  @if $filename == arrow-outlined-right {
    $char: "\E015" $important;
  }
  @if $filename == arrow-outlined-up {
    $char: "\E016" $important;
  }
  @if $filename == arrow-right-small {
    $char: "\E017" $important;
  }
  @if $filename == arrow-right {
    $char: "\E018" $important;
  }
  @if $filename == arrow-up-right {
    $char: "\E019" $important;
  }
  @if $filename == arrow-up {
    $char: "\E01A" $important;
  }
  @if $filename == at {
    $char: "\E01B" $important;
  }
  @if $filename == attach {
    $char: "\E01C" $important;
  }
  @if $filename == award {
    $char: "\E01D" $important;
  }
  @if $filename == backspace {
    $char: "\E01E" $important;
  }
  @if $filename == badge {
    $char: "\E01F" $important;
  }
  @if $filename == balance-sheet {
    $char: "\E020" $important;
  }
  @if $filename == bank {
    $char: "\E021" $important;
  }
  @if $filename == barcode {
    $char: "\E022" $important;
  }
  @if $filename == battery {
    $char: "\E023" $important;
  }
  @if $filename == batttery-charging {
    $char: "\E024" $important;
  }
  @if $filename == behance {
    $char: "\E025" $important;
  }
  @if $filename == bell-off {
    $char: "\E026" $important;
  }
  @if $filename == bell {
    $char: "\E027" $important;
  }
  @if $filename == blocked {
    $char: "\E028" $important;
  }
  @if $filename == book-open {
    $char: "\E029" $important;
  }
  @if $filename == book {
    $char: "\E02A" $important;
  }
  @if $filename == bookmark {
    $char: "\E02B" $important;
  }
  @if $filename == box-behind-box {
    $char: "\E02C" $important;
  }
  @if $filename == box-on-box {
    $char: "\E02D" $important;
  }
  @if $filename == box {
    $char: "\E02E" $important;
  }
  @if $filename == bracket-left {
    $char: "\E02F" $important;
  }
  @if $filename == bracket-right {
    $char: "\E030" $important;
  }
  @if $filename == brackets {
    $char: "\E031" $important;
  }
  @if $filename == branch {
    $char: "\E032" $important;
  }
  @if $filename == briefcase {
    $char: "\E033" $important;
  }
  @if $filename == browser {
    $char: "\E034" $important;
  }
  @if $filename == brush {
    $char: "\E035" $important;
  }
  @if $filename == bulb-off {
    $char: "\E036" $important;
  }
  @if $filename == bulb {
    $char: "\E037" $important;
  }
  @if $filename == business-intelligence {
    $char: "\E038" $important;
  }
  @if $filename == calculator {
    $char: "\E039" $important;
  }
  @if $filename == calendar {
    $char: "\E03A" $important;
  }
  @if $filename == camera {
    $char: "\E03B" $important;
  }
  @if $filename == canban {
    $char: "\E03C" $important;
  }
  @if $filename == car {
    $char: "\E03D" $important;
  }
  @if $filename == cash {
    $char: "\E03E" $important;
  }
  @if $filename == change {
    $char: "\E03F" $important;
  }
  @if $filename == checkbox-off {
    $char: "\E040" $important;
  }
  @if $filename == checkbox-on {
    $char: "\E041" $important;
  }
  @if $filename == checklist {
    $char: "\E042" $important;
  }
  @if $filename == checkmark-circle-divided {
    $char: "\E043" $important;
  }
  @if $filename == checkmark-circle {
    $char: "\E044" $important;
  }
  @if $filename == checkmark-small {
    $char: "\E045" $important;
  }
  @if $filename == checkmark {
    $char: "\E046" $important;
  }
  @if $filename == chevron-down {
    $char: "\E047" $important;
  }
  @if $filename == chevron-left {
    $char: "\E048" $important;
  }
  @if $filename == chevron-right {
    $char: "\E049" $important;
  }
  @if $filename == chevron-up {
    $char: "\E04A" $important;
  }
  @if $filename == clipboard {
    $char: "\E04B" $important;
  }
  @if $filename == clock {
    $char: "\E04C" $important;
  }
  @if $filename == close-circle {
    $char: "\E04D" $important;
  }
  @if $filename == close-square {
    $char: "\E04E" $important;
  }
  @if $filename == close {
    $char: "\E04F" $important;
  }
  @if $filename == cloud-download {
    $char: "\E050" $important;
  }
  @if $filename == cloud-upload {
    $char: "\E051" $important;
  }
  @if $filename == collapse {
    $char: "\E052" $important;
  }
  @if $filename == color-palette {
    $char: "\E053" $important;
  }
  @if $filename == color-picker {
    $char: "\E054" $important;
  }
  @if $filename == column-settings {
    $char: "\E055" $important;
  }
  @if $filename == compass {
    $char: "\E056" $important;
  }
  @if $filename == copy-from {
    $char: "\E057" $important;
  }
  @if $filename == copy {
    $char: "\E058" $important;
  }
  @if $filename == credit-card {
    $char: "\E059" $important;
  }
  @if $filename == credit-note {
    $char: "\E05A" $important;
  }
  @if $filename == cross-circle {
    $char: "\E05B" $important;
  }
  @if $filename == cross-small {
    $char: "\E05C" $important;
  }
  @if $filename == cubes {
    $char: "\E05D" $important;
  }
  @if $filename == dashboard {
    $char: "\E05E" $important;
  }
  @if $filename == decline {
    $char: "\E05F" $important;
  }
  @if $filename == delivery-note {
    $char: "\E060" $important;
  }
  @if $filename == digital-product {
    $char: "\E061" $important;
  }
  @if $filename == dollar {
    $char: "\E062" $important;
  }
  @if $filename == drag {
    $char: "\E063" $important;
  }
  @if $filename == droplet-off {
    $char: "\E064" $important;
  }
  @if $filename == droplet {
    $char: "\E065" $important;
  }
  @if $filename == edit-2 {
    $char: "\E066" $important;
  }
  @if $filename == edit {
    $char: "\E067" $important;
  }
  @if $filename == email {
    $char: "\E068" $important;
  }
  @if $filename == eraser {
    $char: "\E069" $important;
  }
  @if $filename == euro {
    $char: "\E06A" $important;
  }
  @if $filename == exchange-return {
    $char: "\E06B" $important;
  }
  @if $filename == expand {
    $char: "\E06C" $important;
  }
  @if $filename == external-link {
    $char: "\E06D" $important;
  }
  @if $filename == eye-off {
    $char: "\E06E" $important;
  }
  @if $filename == eye {
    $char: "\E06F" $important;
  }
  @if $filename == facebook {
    $char: "\E070" $important;
  }
  @if $filename == factory {
    $char: "\E071" $important;
  }
  @if $filename == female {
    $char: "\E072" $important;
  }
  @if $filename == file-add {
    $char: "\E073" $important;
  }
  @if $filename == file-csv {
    $char: "\E074" $important;
  }
  @if $filename == file-remove {
    $char: "\E075" $important;
  }
  @if $filename == file-text {
    $char: "\E076" $important;
  }
  @if $filename == file-xls {
    $char: "\E077" $important;
  }
  @if $filename == file-xlsx {
    $char: "\E078" $important;
  }
  @if $filename == file {
    $char: "\E079" $important;
  }
  @if $filename == film {
    $char: "\E07A" $important;
  }
  @if $filename == fire {
    $char: "\E07B" $important;
  }
  @if $filename == flag {
    $char: "\E07C" $important;
  }
  @if $filename == flash-off {
    $char: "\E07D" $important;
  }
  @if $filename == flash {
    $char: "\E07E" $important;
  }
  @if $filename == folder-add {
    $char: "\E07F" $important;
  }
  @if $filename == folder-open {
    $char: "\E080" $important;
  }
  @if $filename == folder-remove {
    $char: "\E081" $important;
  }
  @if $filename == folder {
    $char: "\E082" $important;
  }
  @if $filename == full-screen {
    $char: "\E083" $important;
  }
  @if $filename == funnel {
    $char: "\E084" $important;
  }
  @if $filename == gift {
    $char: "\E085" $important;
  }
  @if $filename == github {
    $char: "\E086" $important;
  }
  @if $filename == globe-2 {
    $char: "\E087" $important;
  }
  @if $filename == globe {
    $char: "\E088" $important;
  }
  @if $filename == goods-receipt {
    $char: "\E089" $important;
  }
  @if $filename == google {
    $char: "\E08A" $important;
  }
  @if $filename == grid {
    $char: "\E08B" $important;
  }
  @if $filename == hard-drive {
    $char: "\E08C" $important;
  }
  @if $filename == headphones {
    $char: "\E08D" $important;
  }
  @if $filename == heart {
    $char: "\E08E" $important;
  }
  @if $filename == hide-sidebar {
    $char: "\E08F" $important;
  }
  @if $filename == home {
    $char: "\E090" $important;
  }
  @if $filename == image {
    $char: "\E091" $important;
  }
  @if $filename == inbox {
    $char: "\E092" $important;
  }
  @if $filename == incoming-delivery {
    $char: "\E093" $important;
  }
  @if $filename == incoming-invoice {
    $char: "\E094" $important;
  }
  @if $filename == incoming {
    $char: "\E095" $important;
  }
  @if $filename == info {
    $char: "\E096" $important;
  }
  @if $filename == invoice {
    $char: "\E097" $important;
  }
  @if $filename == keypad {
    $char: "\E098" $important;
  }
  @if $filename == layers {
    $char: "\E099" $important;
  }
  @if $filename == layout-a {
    $char: "\E09A" $important;
  }
  @if $filename == layout-b {
    $char: "\E09B" $important;
  }
  @if $filename == layout-c {
    $char: "\E09C" $important;
  }
  @if $filename == layout-d {
    $char: "\E09D" $important;
  }
  @if $filename == layout {
    $char: "\E09E" $important;
  }
  @if $filename == link {
    $char: "\E09F" $important;
  }
  @if $filename == linkedin {
    $char: "\E0A0" $important;
  }
  @if $filename == list {
    $char: "\E0A1" $important;
  }
  @if $filename == loader-blue {
    $char: "\E0A2" $important;
  }
  @if $filename == loader-grey {
    $char: "\E0A3" $important;
  }
  @if $filename == loader-sky {
    $char: "\E0A4" $important;
  }
  @if $filename == loader-white {
    $char: "\E0A5" $important;
  }
  @if $filename == lock {
    $char: "\E0A6" $important;
  }
  @if $filename == mailbox {
    $char: "\E0A7" $important;
  }
  @if $filename == male {
    $char: "\E0A8" $important;
  }
  @if $filename == map {
    $char: "\E0A9" $important;
  }
  @if $filename == maximize {
    $char: "\E0AA" $important;
  }
  @if $filename == menu-2 {
    $char: "\E0AB" $important;
  }
  @if $filename == menu-close {
    $char: "\E0AC" $important;
  }
  @if $filename == menu {
    $char: "\E0AD" $important;
  }
  @if $filename == message-circle {
    $char: "\E0AE" $important;
  }
  @if $filename == message-square {
    $char: "\E0AF" $important;
  }
  @if $filename == mic-off {
    $char: "\E0B0" $important;
  }
  @if $filename == mic {
    $char: "\E0B1" $important;
  }
  @if $filename == minimize {
    $char: "\E0B2" $important;
  }
  @if $filename == minus-circle-divided {
    $char: "\E0B3" $important;
  }
  @if $filename == minus-circle {
    $char: "\E0B4" $important;
  }
  @if $filename == minus-square {
    $char: "\E0B5" $important;
  }
  @if $filename == minus {
    $char: "\E0B6" $important;
  }
  @if $filename == monitor {
    $char: "\E0B7" $important;
  }
  @if $filename == moon {
    $char: "\E0B8" $important;
  }
  @if $filename == more-horizontal {
    $char: "\E0B9" $important;
  }
  @if $filename == more-vertical {
    $char: "\E0BA" $important;
  }
  @if $filename == mouse_arrow {
    $char: "\E0BB" $important;
  }
  @if $filename == music {
    $char: "\E0BC" $important;
  }
  @if $filename == navigation-2 {
    $char: "\E0BD" $important;
  }
  @if $filename == navigation {
    $char: "\E0BE" $important;
  }
  @if $filename == npm {
    $char: "\E0BF" $important;
  }
  @if $filename == offer {
    $char: "\E0C0" $important;
  }
  @if $filename == open-box {
    $char: "\E0C1" $important;
  }
  @if $filename == open-items-list {
    $char: "\E0C2" $important;
  }
  @if $filename == options-2 {
    $char: "\E0C3" $important;
  }
  @if $filename == options {
    $char: "\E0C4" $important;
  }
  @if $filename == outbound {
    $char: "\E0C5" $important;
  }
  @if $filename == outgoing-delivery {
    $char: "\E0C6" $important;
  }
  @if $filename == outgoing-invoice {
    $char: "\E0C7" $important;
  }
  @if $filename == outgoing {
    $char: "\E0C8" $important;
  }
  @if $filename == pantone {
    $char: "\E0C9" $important;
  }
  @if $filename == pause-circle {
    $char: "\E0CA" $important;
  }
  @if $filename == payment {
    $char: "\E0CB" $important;
  }
  @if $filename == pdf {
    $char: "\E0CC" $important;
  }
  @if $filename == people {
    $char: "\E0CD" $important;
  }
  @if $filename == person-add {
    $char: "\E0CE" $important;
  }
  @if $filename == person-delete {
    $char: "\E0CF" $important;
  }
  @if $filename == person-done {
    $char: "\E0D0" $important;
  }
  @if $filename == person-remove {
    $char: "\E0D1" $important;
  }
  @if $filename == person {
    $char: "\E0D2" $important;
  }
  @if $filename == phone-call {
    $char: "\E0D3" $important;
  }
  @if $filename == phone-missed {
    $char: "\E0D4" $important;
  }
  @if $filename == phone-off {
    $char: "\E0D5" $important;
  }
  @if $filename == phone {
    $char: "\E0D6" $important;
  }
  @if $filename == picking {
    $char: "\E0D7" $important;
  }
  @if $filename == pie-chart-0 {
    $char: "\E0D8" $important;
  }
  @if $filename == pie-chart-25 {
    $char: "\E0D9" $important;
  }
  @if $filename == pie-chart-50 {
    $char: "\E0DA" $important;
  }
  @if $filename == pie-chart-75 {
    $char: "\E0DB" $important;
  }
  @if $filename == pie-chart {
    $char: "\E0DC" $important;
  }
  @if $filename == pin {
    $char: "\E0DD" $important;
  }
  @if $filename == plane {
    $char: "\E0DE" $important;
  }
  @if $filename == play-circle {
    $char: "\E0DF" $important;
  }
  @if $filename == plus-circle-divided {
    $char: "\E0E0" $important;
  }
  @if $filename == plus-circle {
    $char: "\E0E1" $important;
  }
  @if $filename == plus-square {
    $char: "\E0E2" $important;
  }
  @if $filename == plus {
    $char: "\E0E3" $important;
  }
  @if $filename == POS {
    $char: "\E0E4" $important;
  }
  @if $filename == pricelist {
    $char: "\E0E5" $important;
  }
  @if $filename == pricetag {
    $char: "\E0E6" $important;
  }
  @if $filename == printer {
    $char: "\E0E7" $important;
  }
  @if $filename == purchase-order {
    $char: "\E0E8" $important;
  }
  @if $filename == question-mark-circle-divided {
    $char: "\E0E9" $important;
  }
  @if $filename == question-mark-circle {
    $char: "\E0EA" $important;
  }
  @if $filename == radio-button-off {
    $char: "\E0EB" $important;
  }
  @if $filename == radio-button-on {
    $char: "\E0EC" $important;
  }
  @if $filename == radio {
    $char: "\E0ED" $important;
  }
  @if $filename == recording {
    $char: "\E0EE" $important;
  }
  @if $filename == redo-outline {
    $char: "\E0EF" $important;
  }
  @if $filename == redo {
    $char: "\E0F0" $important;
  }
  @if $filename == reports {
    $char: "\E0F1" $important;
  }
  @if $filename == returns {
    $char: "\E0F2" $important;
  }
  @if $filename == rewind-left {
    $char: "\E0F3" $important;
  }
  @if $filename == rewind-right {
    $char: "\E0F4" $important;
  }
  @if $filename == rfm-analysis {
    $char: "\E0F5" $important;
  }
  @if $filename == rocket {
    $char: "\E0F6" $important;
  }
  @if $filename == row-settings {
    $char: "\E0F7" $important;
  }
  @if $filename == sales-order {
    $char: "\E0F8" $important;
  }
  @if $filename == save {
    $char: "\E0F9" $important;
  }
  @if $filename == search {
    $char: "\E0FA" $important;
  }
  @if $filename == server {
    $char: "\E0FB" $important;
  }
  @if $filename == services {
    $char: "\E0FC" $important;
  }
  @if $filename == settings-2 {
    $char: "\E0FD" $important;
  }
  @if $filename == settings-2x {
    $char: "\E0FE" $important;
  }
  @if $filename == settings {
    $char: "\E0FF" $important;
  }
  @if $filename == share {
    $char: "\E100" $important;
  }
  @if $filename == shield-off {
    $char: "\E101" $important;
  }
  @if $filename == shield {
    $char: "\E102" $important;
  }
  @if $filename == shop {
    $char: "\E103" $important;
  }
  @if $filename == shopping-bag {
    $char: "\E104" $important;
  }
  @if $filename == shopping-cart {
    $char: "\E105" $important;
  }
  @if $filename == shuffle-small {
    $char: "\E106" $important;
  }
  @if $filename == shuffle {
    $char: "\E107" $important;
  }
  @if $filename == sign-in {
    $char: "\E108" $important;
  }
  @if $filename == sign-out {
    $char: "\E109" $important;
  }
  @if $filename == skip-back {
    $char: "\E10A" $important;
  }
  @if $filename == skip-forward {
    $char: "\E10B" $important;
  }
  @if $filename == slash {
    $char: "\E10C" $important;
  }
  @if $filename == smartphone {
    $char: "\E10D" $important;
  }
  @if $filename == sort-down {
    $char: "\E10E" $important;
  }
  @if $filename == sort-up {
    $char: "\E10F" $important;
  }
  @if $filename == sort {
    $char: "\E110" $important;
  }
  @if $filename == speaker {
    $char: "\E111" $important;
  }
  @if $filename == star {
    $char: "\E112" $important;
  }
  @if $filename == stock-status {
    $char: "\E113" $important;
  }
  @if $filename == stocktransfer {
    $char: "\E114" $important;
  }
  @if $filename == stop-circle {
    $char: "\E115" $important;
  }
  @if $filename == stop {
    $char: "\E116" $important;
  }
  @if $filename == subscriptions {
    $char: "\E117" $important;
  }
  @if $filename == summary {
    $char: "\E118" $important;
  }
  @if $filename == sun {
    $char: "\E119" $important;
  }
  @if $filename == thermometer-minus {
    $char: "\E11A" $important;
  }
  @if $filename == thermometer-plus {
    $char: "\E11B" $important;
  }
  @if $filename == thermometer {
    $char: "\E11C" $important;
  }
  @if $filename == timer {
    $char: "\E11D" $important;
  }
  @if $filename == toggle-left {
    $char: "\E11E" $important;
  }
  @if $filename == toggle-right {
    $char: "\E11F" $important;
  }
  @if $filename == transactions {
    $char: "\E120" $important;
  }
  @if $filename == translate {
    $char: "\E121" $important;
  }
  @if $filename == trash-2 {
    $char: "\E122" $important;
  }
  @if $filename == trash {
    $char: "\E123" $important;
  }
  @if $filename == tv {
    $char: "\E124" $important;
  }
  @if $filename == twitter {
    $char: "\E125" $important;
  }
  @if $filename == umbrella {
    $char: "\E126" $important;
  }
  @if $filename == undo-outline {
    $char: "\E127" $important;
  }
  @if $filename == undo {
    $char: "\E128" $important;
  }
  @if $filename == unlink {
    $char: "\E129" $important;
  }
  @if $filename == unlock {
    $char: "\E12A" $important;
  }
  @if $filename == user-defined {
    $char: "\E12B" $important;
  }
  @if $filename == video-off {
    $char: "\E12C" $important;
  }
  @if $filename == video {
    $char: "\E12D" $important;
  }
  @if $filename == volume-down {
    $char: "\E12E" $important;
  }
  @if $filename == volume-off {
    $char: "\E12F" $important;
  }
  @if $filename == volume-up {
    $char: "\E130" $important;
  }
  @if $filename == volume {
    $char: "\E131" $important;
  }
  @if $filename == warehouse-process {
    $char: "\E132" $important;
  }
  @if $filename == warehouse {
    $char: "\E133" $important;
  }
  @if $filename == warehousing {
    $char: "\E134" $important;
  }
  @if $filename == weight {
    $char: "\E135" $important;
  }
  @if $filename == xyz-analysis {
    $char: "\E136" $important;
  }

  @return $char;
}

@mixin icon($filename, $insert: after, $extend: true, $important:null) {
  &:#{$insert} {
    @if $extend {
      @extend %icon;
    } @else {
      @include icon-styles;
    }
    content: icon-char($filename) $important;
  }
}

.icon-abc-analysis {
  @include icon(abc-analysis);
}
.icon-accountant-report {
  @include icon(accountant-report);
}
.icon-alert-circle {
  @include icon(alert-circle);
}
.icon-alert-triangle-divided {
  @include icon(alert-triangle-divided);
}
.icon-alert-triangle {
  @include icon(alert-triangle);
}
.icon-archive {
  @include icon(archive);
}
.icon-arrow-circle-down {
  @include icon(arrow-circle-down);
}
.icon-arrow-circle-left {
  @include icon(arrow-circle-left);
}
.icon-arrow-circle-right {
  @include icon(arrow-circle-right);
}
.icon-arrow-circle-up {
  @include icon(arrow-circle-up);
}
.icon-arrow-circle {
  @include icon(arrow-circle);
}
.icon-arrow-down {
  @include icon(arrow-down);
}
.icon-arrow-filled-down {
  @include icon(arrow-filled-down);
}
.icon-arrow-filled-left {
  @include icon(arrow-filled-left);
}
.icon-arrow-filled-right {
  @include icon(arrow-filled-right);
}
.icon-arrow-filled-up {
  @include icon(arrow-filled-up);
}
.icon-arrow-left {
  @include icon(arrow-left);
}
.icon-arrow-micro-down {
  @include icon(arrow-micro-down);
}
.icon-arrow-micro-up {
  @include icon(arrow-micro-up);
}
.icon-arrow-outlined-down {
  @include icon(arrow-outlined-down);
}
.icon-arrow-outlined-right {
  @include icon(arrow-outlined-right);
}
.icon-arrow-outlined-up {
  @include icon(arrow-outlined-up);
}
.icon-arrow-right-small {
  @include icon(arrow-right-small);
}
.icon-arrow-right {
  @include icon(arrow-right);
}
.icon-arrow-up-right {
  @include icon(arrow-up-right);
}
.icon-arrow-up {
  @include icon(arrow-up);
}
.icon-at {
  @include icon(at);
}
.icon-attach {
  @include icon(attach);
}
.icon-award {
  @include icon(award);
}
.icon-backspace {
  @include icon(backspace);
}
.icon-badge {
  @include icon(badge);
}
.icon-balance-sheet {
  @include icon(balance-sheet);
}
.icon-bank {
  @include icon(bank);
}
.icon-barcode {
  @include icon(barcode);
}
.icon-battery {
  @include icon(battery);
}
.icon-batttery-charging {
  @include icon(batttery-charging);
}
.icon-behance {
  @include icon(behance);
}
.icon-bell-off {
  @include icon(bell-off);
}
.icon-bell {
  @include icon(bell);
}
.icon-blocked {
  @include icon(blocked);
}
.icon-book-open {
  @include icon(book-open);
}
.icon-book {
  @include icon(book);
}
.icon-bookmark {
  @include icon(bookmark);
}
.icon-box-behind-box {
  @include icon(box-behind-box);
}
.icon-box-on-box {
  @include icon(box-on-box);
}
.icon-box {
  @include icon(box);
}
.icon-bracket-left {
  @include icon(bracket-left);
}
.icon-bracket-right {
  @include icon(bracket-right);
}
.icon-brackets {
  @include icon(brackets);
}
.icon-branch {
  @include icon(branch);
}
.icon-briefcase {
  @include icon(briefcase);
}
.icon-browser {
  @include icon(browser);
}
.icon-brush {
  @include icon(brush);
}
.icon-bulb-off {
  @include icon(bulb-off);
}
.icon-bulb {
  @include icon(bulb);
}
.icon-business-intelligence {
  @include icon(business-intelligence);
}
.icon-calculator {
  @include icon(calculator);
}
.icon-calendar {
  @include icon(calendar);
}
.icon-camera {
  @include icon(camera);
}
.icon-canban {
  @include icon(canban);
}
.icon-car {
  @include icon(car);
}
.icon-cash {
  @include icon(cash);
}
.icon-change {
  @include icon(change);
}
.icon-checkbox-off {
  @include icon(checkbox-off);
}
.icon-checkbox-on {
  @include icon(checkbox-on);
}
.icon-checklist {
  @include icon(checklist);
}
.icon-checkmark-circle-divided {
  @include icon(checkmark-circle-divided);
}
.icon-checkmark-circle {
  @include icon(checkmark-circle);
}
.icon-checkmark-small {
  @include icon(checkmark-small);
}
.icon-checkmark {
  @include icon(checkmark);
}
.icon-chevron-down {
  @include icon(chevron-down);
}
.icon-chevron-left {
  @include icon(chevron-left);
}
.icon-chevron-right {
  @include icon(chevron-right);
}
.icon-chevron-up {
  @include icon(chevron-up);
}
.icon-clipboard {
  @include icon(clipboard);
}
.icon-clock {
  @include icon(clock);
}
.icon-close-circle {
  @include icon(close-circle);
}
.icon-close-square {
  @include icon(close-square);
}
.icon-close {
  @include icon(close);
}
.icon-cloud-download {
  @include icon(cloud-download);
}
.icon-cloud-upload {
  @include icon(cloud-upload);
}
.icon-collapse {
  @include icon(collapse);
}
.icon-color-palette {
  @include icon(color-palette);
}
.icon-color-picker {
  @include icon(color-picker);
}
.icon-column-settings {
  @include icon(column-settings);
}
.icon-compass {
  @include icon(compass);
}
.icon-copy-from {
  @include icon(copy-from);
}
.icon-copy {
  @include icon(copy);
}
.icon-credit-card {
  @include icon(credit-card);
}
.icon-credit-note {
  @include icon(credit-note);
}
.icon-cross-circle {
  @include icon(cross-circle);
}
.icon-cross-small {
  @include icon(cross-small);
}
.icon-cubes {
  @include icon(cubes);
}
.icon-dashboard {
  @include icon(dashboard);
}
.icon-decline {
  @include icon(decline);
}
.icon-delivery-note {
  @include icon(delivery-note);
}
.icon-digital-product {
  @include icon(digital-product);
}
.icon-dollar {
  @include icon(dollar);
}
.icon-drag {
  @include icon(drag);
}
.icon-droplet-off {
  @include icon(droplet-off);
}
.icon-droplet {
  @include icon(droplet);
}
.icon-edit-2 {
  @include icon(edit-2);
}
.icon-edit {
  @include icon(edit);
}
.icon-email {
  @include icon(email);
}
.icon-eraser {
  @include icon(eraser);
}
.icon-euro {
  @include icon(euro);
}
.icon-exchange-return {
  @include icon(exchange-return);
}
.icon-expand {
  @include icon(expand);
}
.icon-external-link {
  @include icon(external-link);
}
.icon-eye-off {
  @include icon(eye-off);
}
.icon-eye {
  @include icon(eye);
}
.icon-facebook {
  @include icon(facebook);
}
.icon-factory {
  @include icon(factory);
}
.icon-female {
  @include icon(female);
}
.icon-file-add {
  @include icon(file-add);
}
.icon-file-csv {
  @include icon(file-csv);
}
.icon-file-remove {
  @include icon(file-remove);
}
.icon-file-text {
  @include icon(file-text);
}
.icon-file-xls {
  @include icon(file-xls);
}
.icon-file-xlsx {
  @include icon(file-xlsx);
}
.icon-file {
  @include icon(file);
}
.icon-film {
  @include icon(film);
}
.icon-fire {
  @include icon(fire);
}
.icon-flag {
  @include icon(flag);
}
.icon-flash-off {
  @include icon(flash-off);
}
.icon-flash {
  @include icon(flash);
}
.icon-folder-add {
  @include icon(folder-add);
}
.icon-folder-open {
  @include icon(folder-open);
}
.icon-folder-remove {
  @include icon(folder-remove);
}
.icon-folder {
  @include icon(folder);
}
.icon-full-screen {
  @include icon(full-screen);
}
.icon-funnel {
  @include icon(funnel);
}
.icon-gift {
  @include icon(gift);
}
.icon-github {
  @include icon(github);
}
.icon-globe-2 {
  @include icon(globe-2);
}
.icon-globe {
  @include icon(globe);
}
.icon-goods-receipt {
  @include icon(goods-receipt);
}
.icon-google {
  @include icon(google);
}
.icon-grid {
  @include icon(grid);
}
.icon-hard-drive {
  @include icon(hard-drive);
}
.icon-headphones {
  @include icon(headphones);
}
.icon-heart {
  @include icon(heart);
}
.icon-hide-sidebar {
  @include icon(hide-sidebar);
}
.icon-home {
  @include icon(home);
}
.icon-image {
  @include icon(image);
}
.icon-inbox {
  @include icon(inbox);
}
.icon-incoming-delivery {
  @include icon(incoming-delivery);
}
.icon-incoming-invoice {
  @include icon(incoming-invoice);
}
.icon-incoming {
  @include icon(incoming);
}
.icon-info {
  @include icon(info);
}
.icon-invoice {
  @include icon(invoice);
}
.icon-keypad {
  @include icon(keypad);
}
.icon-layers {
  @include icon(layers);
}
.icon-layout-a {
  @include icon(layout-a);
}
.icon-layout-b {
  @include icon(layout-b);
}
.icon-layout-c {
  @include icon(layout-c);
}
.icon-layout-d {
  @include icon(layout-d);
}
.icon-layout {
  @include icon(layout);
}
.icon-link {
  @include icon(link);
}
.icon-linkedin {
  @include icon(linkedin);
}
.icon-list {
  @include icon(list);
}
.icon-loader-blue {
  @include icon(loader-blue);
}
.icon-loader-grey {
  @include icon(loader-grey);
}
.icon-loader-sky {
  @include icon(loader-sky);
}
.icon-loader-white {
  @include icon(loader-white);
}
.icon-lock {
  @include icon(lock);
}
.icon-mailbox {
  @include icon(mailbox);
}
.icon-male {
  @include icon(male);
}
.icon-map {
  @include icon(map);
}
.icon-maximize {
  @include icon(maximize);
}
.icon-menu-2 {
  @include icon(menu-2);
}
.icon-menu-close {
  @include icon(menu-close);
}
.icon-menu {
  @include icon(menu);
}
.icon-message-circle {
  @include icon(message-circle);
}
.icon-message-square {
  @include icon(message-square);
}
.icon-mic-off {
  @include icon(mic-off);
}
.icon-mic {
  @include icon(mic);
}
.icon-minimize {
  @include icon(minimize);
}
.icon-minus-circle-divided {
  @include icon(minus-circle-divided);
}
.icon-minus-circle {
  @include icon(minus-circle);
}
.icon-minus-square {
  @include icon(minus-square);
}
.icon-minus {
  @include icon(minus);
}
.icon-monitor {
  @include icon(monitor);
}
.icon-moon {
  @include icon(moon);
}
.icon-more-horizontal {
  @include icon(more-horizontal);
}
.icon-more-vertical {
  @include icon(more-vertical);
}
.icon-mouse_arrow {
  @include icon(mouse_arrow);
}
.icon-music {
  @include icon(music);
}
.icon-navigation-2 {
  @include icon(navigation-2);
}
.icon-navigation {
  @include icon(navigation);
}
.icon-npm {
  @include icon(npm);
}
.icon-offer {
  @include icon(offer);
}
.icon-open-box {
  @include icon(open-box);
}
.icon-open-items-list {
  @include icon(open-items-list);
}
.icon-options-2 {
  @include icon(options-2);
}
.icon-options {
  @include icon(options);
}
.icon-outbound {
  @include icon(outbound);
}
.icon-outgoing-delivery {
  @include icon(outgoing-delivery);
}
.icon-outgoing-invoice {
  @include icon(outgoing-invoice);
}
.icon-outgoing {
  @include icon(outgoing);
}
.icon-pantone {
  @include icon(pantone);
}
.icon-pause-circle {
  @include icon(pause-circle);
}
.icon-payment {
  @include icon(payment);
}
.icon-pdf {
  @include icon(pdf);
}
.icon-people {
  @include icon(people);
}
.icon-person-add {
  @include icon(person-add);
}
.icon-person-delete {
  @include icon(person-delete);
}
.icon-person-done {
  @include icon(person-done);
}
.icon-person-remove {
  @include icon(person-remove);
}
.icon-person {
  @include icon(person);
}
.icon-phone-call {
  @include icon(phone-call);
}
.icon-phone-missed {
  @include icon(phone-missed);
}
.icon-phone-off {
  @include icon(phone-off);
}
.icon-phone {
  @include icon(phone);
}
.icon-picking {
  @include icon(picking);
}
.icon-pie-chart-0 {
  @include icon(pie-chart-0);
}
.icon-pie-chart-25 {
  @include icon(pie-chart-25);
}
.icon-pie-chart-50 {
  @include icon(pie-chart-50);
}
.icon-pie-chart-75 {
  @include icon(pie-chart-75);
}
.icon-pie-chart {
  @include icon(pie-chart);
}
.icon-pin {
  @include icon(pin);
}
.icon-plane {
  @include icon(plane);
}
.icon-play-circle {
  @include icon(play-circle);
}
.icon-plus-circle-divided {
  @include icon(plus-circle-divided);
}
.icon-plus-circle {
  @include icon(plus-circle);
}
.icon-plus-square {
  @include icon(plus-square);
}
.icon-plus {
  @include icon(plus);
}
.icon-POS {
  @include icon(POS);
}
.icon-pricelist {
  @include icon(pricelist);
}
.icon-pricetag {
  @include icon(pricetag);
}
.icon-printer {
  @include icon(printer);
}
.icon-purchase-order {
  @include icon(purchase-order);
}
.icon-question-mark-circle-divided {
  @include icon(question-mark-circle-divided);
}
.icon-question-mark-circle {
  @include icon(question-mark-circle);
}
.icon-radio-button-off {
  @include icon(radio-button-off);
}
.icon-radio-button-on {
  @include icon(radio-button-on);
}
.icon-radio {
  @include icon(radio);
}
.icon-recording {
  @include icon(recording);
}
.icon-redo-outline {
  @include icon(redo-outline);
}
.icon-redo {
  @include icon(redo);
}
.icon-reports {
  @include icon(reports);
}
.icon-returns {
  @include icon(returns);
}
.icon-rewind-left {
  @include icon(rewind-left);
}
.icon-rewind-right {
  @include icon(rewind-right);
}
.icon-rfm-analysis {
  @include icon(rfm-analysis);
}
.icon-rocket {
  @include icon(rocket);
}
.icon-row-settings {
  @include icon(row-settings);
}
.icon-sales-order {
  @include icon(sales-order);
}
.icon-save {
  @include icon(save);
}
.icon-search {
  @include icon(search);
}
.icon-server {
  @include icon(server);
}
.icon-services {
  @include icon(services);
}
.icon-settings-2 {
  @include icon(settings-2);
}
.icon-settings-2x {
  @include icon(settings-2x);
}
.icon-settings {
  @include icon(settings);
}
.icon-share {
  @include icon(share);
}
.icon-shield-off {
  @include icon(shield-off);
}
.icon-shield {
  @include icon(shield);
}
.icon-shop {
  @include icon(shop);
}
.icon-shopping-bag {
  @include icon(shopping-bag);
}
.icon-shopping-cart {
  @include icon(shopping-cart);
}
.icon-shuffle-small {
  @include icon(shuffle-small);
}
.icon-shuffle {
  @include icon(shuffle);
}
.icon-sign-in {
  @include icon(sign-in);
}
.icon-sign-out {
  @include icon(sign-out);
}
.icon-skip-back {
  @include icon(skip-back);
}
.icon-skip-forward {
  @include icon(skip-forward);
}
.icon-slash {
  @include icon(slash);
}
.icon-smartphone {
  @include icon(smartphone);
}
.icon-sort-down {
  @include icon(sort-down);
}
.icon-sort-up {
  @include icon(sort-up);
}
.icon-sort {
  @include icon(sort);
}
.icon-speaker {
  @include icon(speaker);
}
.icon-star {
  @include icon(star);
}
.icon-stock-status {
  @include icon(stock-status);
}
.icon-stocktransfer {
  @include icon(stocktransfer);
}
.icon-stop-circle {
  @include icon(stop-circle);
}
.icon-stop {
  @include icon(stop);
}
.icon-subscriptions {
  @include icon(subscriptions);
}
.icon-summary {
  @include icon(summary);
}
.icon-sun {
  @include icon(sun);
}
.icon-thermometer-minus {
  @include icon(thermometer-minus);
}
.icon-thermometer-plus {
  @include icon(thermometer-plus);
}
.icon-thermometer {
  @include icon(thermometer);
}
.icon-timer {
  @include icon(timer);
}
.icon-toggle-left {
  @include icon(toggle-left);
}
.icon-toggle-right {
  @include icon(toggle-right);
}
.icon-transactions {
  @include icon(transactions);
}
.icon-translate {
  @include icon(translate);
}
.icon-trash-2 {
  @include icon(trash-2);
}
.icon-trash {
  @include icon(trash);
}
.icon-tv {
  @include icon(tv);
}
.icon-twitter {
  @include icon(twitter);
}
.icon-umbrella {
  @include icon(umbrella);
}
.icon-undo-outline {
  @include icon(undo-outline);
}
.icon-undo {
  @include icon(undo);
}
.icon-unlink {
  @include icon(unlink);
}
.icon-unlock {
  @include icon(unlock);
}
.icon-user-defined {
  @include icon(user-defined);
}
.icon-video-off {
  @include icon(video-off);
}
.icon-video {
  @include icon(video);
}
.icon-volume-down {
  @include icon(volume-down);
}
.icon-volume-off {
  @include icon(volume-off);
}
.icon-volume-up {
  @include icon(volume-up);
}
.icon-volume {
  @include icon(volume);
}
.icon-warehouse-process {
  @include icon(warehouse-process);
}
.icon-warehouse {
  @include icon(warehouse);
}
.icon-warehousing {
  @include icon(warehousing);
}
.icon-weight {
  @include icon(weight);
}
.icon-xyz-analysis {
  @include icon(xyz-analysis);
}
