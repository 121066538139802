.select-wrapper {
  min-width: 170px;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.with-label {
    height: 70px;
    padding-top: 22px;

    .select-box {
      &-label {
        top: 0 !important;
      }
    }
  }

  .select-icon {
    position: absolute;
    top: 50%;
    left: 10px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 10;
    font-size: 20px;
    transition: .3s;

    .icon {
      color: #5e6681;
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: -10px;
      transform: translateY(-50%);
      border-radius: 1px;
      height: 38px;
      width: 2px;
      background-color: #3e465f;
      transition: .3s;
    }
  }

  .ng-select {
    &-container {
      background-color: #282d3d;
      -webkit-transition: background-color .3s;
      -o-transition: background-color .3s;
      transition: background-color .3s;
      border: none;
      color: $color-white;
      box-shadow: none;
      border-radius: 12px;

      &:hover {
        background-color: #3e465f;
      }

      .ng-value-container {
        padding-left: 16px;
        height: 38px;

        .ng-value {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          height: 100%;
          color: $color-white;
          font-weight: 500;
          line-height: 18px;
          font-size: 14px;
          z-index: 2;

          img {
            height: 16px;
            width: 16px;
            margin-right: 8px;

            & + span {
              font-family: 'Inter-SemiBold', sans-serif;
              letter-spacing: 0;
            }
          }
        }

        .ng-placeholder {
          letter-spacing: -0.2px;
          line-height: 18px;
          font-size: 14px;
          font-weight: normal;
          font-family: "Inter-Regular", sans-serif;
          color: #9ca4be;
          z-index: 1;
        }
      }
    }

    &.with-label-box {
      .ng-select-container .ng-value-container .ng-value {
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
      }
    }

    .ng-clear-wrapper {
      display: none;
    }

    .ng-arrow-wrapper {
      width: 24px;
      height: 24px;
      border-radius: 6px;
      margin-right: 8px;
      padding: 0;
      -webkit-transition: .3s;
      -o-transition: .3s;
      transition: .3s;

      .ng-arrow {
        border: none;
        width: 100%;
        height: 100%;
        top: 0;
        background: url('/assets/img/arrow-micro-down.svg') no-repeat center;
        -webkit-transition: .3s;
        -o-transition: .3s;
        transition: .3s;
      }

      &:hover {
        background-color: $color-main-300;
      }
    }

    &.ng-select-single .ng-select-container {
      height: 48px;
    }

    &.ng-select-opened {
      //box-shadow: 0 40px 48px 0 rgb(28 32 43 / 24%);

      &.ng-select-top {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        margin-bottom: 0;

        .ng-select-container {
          background-color: $color-main-400 !important;

          .ng-placeholder {
            color: #cdd1df;
          }
        }
      }

      &.ng-select-bottom {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        margin-bottom: 0;

        .ng-select-container {
          background-color: $color-main-400 !important;

          .ng-placeholder {
            color: #cdd1df;
          }
        }
      }

      &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: $color-main-300;
        border-radius: 12px;
      }
    }

    &.ng-select-opened.ng-select-bottom>.ng-select-container {
      background-color: $color-main-400;
    }

    &.ng-select-opened>.ng-select-container .ng-arrow {
      background-image: url('/assets/img/close.svg');
    }

    .ng-dropdown-panel {
      background-color: $color-active-input;
      border: none;
      overflow: hidden;
      max-height: 600px;
      box-shadow: 0 40px 48px 0 rgba(28, 32, 43, 0.24);

      .ng-dropdown-panel-items {
       max-height: 100%;
      }

      &.ng-select-top {
        border-top-right-radius: 12px;
        border-top-left-radius: 12px;
      }

      &.ng-select-bottom {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
      }

      .ng-dropdown-panel-items {
        border-radius: inherit;
        padding: 8px;
        margin-top: 8px;

        &::-webkit-scrollbar {
          background-color: $color-main-500;
          width: 8px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $color-main-200;
          border-radius: 8px;

          &:hover {
            background-color: $color-main-100;
          }
        }

        .ng-option {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          background-color: $color-active-input;
          padding: 8px 16px;
          height: 40px;
          font-size: 14px;
          color: $color-white;
          font-weight: 500;
          line-height: 18px;
          -webkit-transition: .3s;
          -o-transition: .3s;
          transition: .3s;
          margin: 0 2px 0;
          border-radius: 10px;
          width: calc(100% - 4px);

          &:hover {
            background-color: $color-main-300;
          }

          img {
            margin-right: 8px;
            width: 16px;
            height: 16px;

            & + span {
              font-family: 'Inter-Medium', sans-serif;
              letter-spacing: 0;
              font-weight: 500;
            }
          }

          .icon {
            + span {
              font-family: 'Inter-Medium', sans-serif;
              letter-spacing: 0;
            }

            &-globe {
              color: $color-blue;
              position: relative;
              z-index: 1;
              width: 18px;
              height: 18px;
              font-size: 18px;
              line-height: 18px;

              &:before {
                content: '';
                position: absolute;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                z-index: -1;
                background-color: $color-white;
              }
            }
          }
        }
      }
    }

    &.ng-select-single .ng-select-container .ng-value-container .ng-input {
      padding-left: 16px;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);

      input {
        color: $color-white;
      }
    }

    &.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
      border-color: transparent;
      //-webkit-box-shadow: inset 0 -2px 2px 0 rgba(0, 0, 0, 0.02), 0 1px 4px 0 rgba(48, 54, 74, 0.2);
      //box-shadow: inset 0 -2px 2px 0 rgba(0, 0, 0, 0.02), 0 1px 4px 0 rgba(48, 54, 74, 0.2);
      box-shadow: none;
    }

    &.ng-select-disabled>.ng-select-container {
      cursor: not-allowed;

      .ng-arrow-wrapper:hover {
        background-color: transparent;
        cursor: not-allowed;
      }

      .ng-value-container {
        .ng-value {
          cursor: not-allowed !important;

          img {
            -webkit-filter: grayscale(1) opacity(0.32);
            filter: grayscale(1) opacity(0.32);
          }
        }
      }
    }
  }

  &.select-lang {
    min-width: auto;

    .ng-select-container {
      border-radius: 16px;
      cursor: pointer;
    }

    &:hover {
      .select-icon {
        pointer-events: none;

        .icon {
          color: #9ca4be;
        }

        &:after {
          background-color: #282d3d;
        }
      }

      .select-box {
        background-color: transparent !important;

        &-label {
          color: #9ca4be;
        }
      }

      .ng-arrow-wrapper {
        .ng-arrow {
          background: url('/assets/img/arrow-micro-down-hover.svg') no-repeat center;
        }

        &:hover {
          background-color: #282d3d;
        }
      }
    }

    &.opened {
      .select-box {
        background-color: transparent;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;

        &-label {
          color: #9ca4be;
        }
      }

      .select-icon {
        .icon {
          color: #9ca4be;
        }

        &:after {
          background-color: #282d3d;
        }
      }
    }

    .ng-select.ng-select-opened:before {
      border-radius: 16px;
    }

    .ng-select {
      .ng-dropdown-panel {
        background-color: #3e465f;

        .ng-dropdown-panel-items {
          margin-top: 2px;
          padding: 0 0 4px;

          .ng-option {
            background-color: transparent;
            border-radius: 12px;
            margin: 0 0 0 4px;
            width: calc(100% - 8px);

            span {
              font-size: 14px;
              font-weight: 500;
              line-height: 18px;
              font-family: 'Inter-Medium', sans-serif;
            }

            &:hover {
              background-color: #30364a;
            }
          }
        }
      }

      .ng-dropdown-panel.ng-select-bottom {
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
      }

      .ng-select-container {

      }

      &.ng-select-opened {
        background-color: #3f4660;

        &.ng-select-bottom {
          border-radius: 16px 16px 0 0 !important;
        }

        &.ng-select-top {
          border-radius: 0 0 16px 16px !important;
        }

        &:hover .ng-arrow-wrapper {
          .ng-arrow {
            background: url('/assets/img/close.svg') no-repeat center;
          }
        }

        .ng-select-container {
          z-index: 9;
          border-radius: 14px;
          background-color: #30364a;
          box-shadow: 0 0 0 2px #3e465f inset;
        }
      }
    }
  }

  .select-box {
    &-label {
      position: absolute;
      top: 14px;
      left: 59px;
      color: #5e6681;
      z-index: 10;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0;
      font-family: "Inter-SemiBold", sans-serif;
      pointer-events: none;
    }
  }

  &.disabled {
    .select-icon {
      cursor: not-allowed;

      .icon {
        color: #30364a;
      }

      .select-box {
        &-label {
          cursor: not-allowed;
        }
      }

      &:after {
        background-color: #282D3D !important;
      }
    }

    .select-box-label {
      cursor: not-allowed;
      color: #30364a;

      .icon {
        color: #30364a;
      }
    }
  }

  //Style for white type
  &.white {
    .select-box {
      &-label {
        top: -22px;
        left: 0;
        color: $color-main-100;
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
        letter-spacing: 0;
      }
    }
    .ng-select {
      &-container {
        background-color: #eceef3;
        box-shadow: none;
        cursor: pointer;

        .ng-value-container {
          .ng-value {
            font-family: Rubik , Roboto, "Helvetica Neue", sans-serif;
            font-weight: 400;
            color: $color-main-600;
            -webkit-box-align: center !important;
            -ms-flex-align: center !important;
            align-items: center !important;
          }
        }

        .ng-arrow-wrapper:hover {
          background-color: transparent;
        }
      }

      &.ng-select-opened {
        box-shadow: none;

        .ng-select-container {
          -webkit-transition: 0s;
          -o-transition: 0s;
          transition: 0s;
          background: #30364a !important;
          -webkit-box-shadow: 0 0 0 2px #30364a;
          box-shadow: 0 0 0 2px #30364a;

          .ng-value-container {
            .ng-value {
              color: $color-white;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
            }
          }

          .ng-arrow-wrapper:hover {
            background-color: $color-main-300;
          }

          &:before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: $color-main-300;
            border-radius: 12px;
          }
        }
      }

      .ng-dropdown-panel {
        left: -2px;
        width: calc(100% + 4px);

        .ng-dropdown-panel-items .ng-option {
          font-weight: 400;
        }
      }

      &:hover {
        .ng-select-container {
          background-color: #e0e2eb;

        }
      }
    }
  }

  //with label icon
  &.select-icon {
    .ng-select {
      &-container {
        .ng-value-container {
          padding-left: 60px;
        }
      }

      &.ng-select-single .ng-select-container {
        height: 64px;
      }

      &.ng-select-single .ng-select-container .ng-value-container .ng-input {
        padding-left: 60px;
      }
    }

    &.disabled {
      .ng-select {
        &.ng-select-disabled>.ng-select-container {
          background-color: $disabled-dropdown-color;
          cursor: not-allowed;

          &:hover {
            background-color: $disabled-dropdown-color;
          }

          .ng-value-container {
            .ng-value {
              color: #30364a;
            }
          }

          .ng-arrow-wrapper {

            .ng-arrow {
              background: url("/assets/img/arrow-micro-down-disabled.svg");
            }

            &:hover {
              background-color: transparent;
              cursor: not-allowed;
            }
          }
        }
      }
    }
  }
}
