/* You can add global styles to this file, and also import other style files */
@import "assets/styles/variables";

// Libraries
@import "assets/styles/bootstrap";

@import "assets/styles/mixins/iconfont";
@import "~@ng-select/ng-select/themes/default.theme.css";

//ng-select customization
@import "assets/styles/dropdown";

//slider customization
@import "assets/styles/slider";

//tooltip customization
@import "assets/styles/tooltip";

//tab customization
@import "assets/styles/tab";

//main pages
@import "assets/styles/page";

//modal window
@import "assets/styles/modal-window";

@import "assets/styles/mixins/mixins";

@import url('https://fonts.googleapis.com/css?family=Rubik:400,500');

@font-face {
  font-family: 'Rubik-Regular';
  src: local('Rubik'), local('Rubik-Regular'),
  url('assets/fonts/Rubik-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik-medium';
  src: local('Rubik Medium'), local('Rubik-Medium'),
  url('assets/fonts/Rubik-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik-Bold';
  src: local('Rubik Bold'), local('Rubik-Bold'),
  url('assets/fonts/Rubik-Bold.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter-Regular';
  src: local('Inter Regular'), local('Inter-Regular'),
  url('assets/fonts/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter-SemiBold';
  src: local('Inter SemiBold'), local('Inter-SemiBold'),
  url('assets/fonts/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter-ExtraBold';
  src: local('Inter ExtraBold'), local('Inter-ExtraBold'),
  url('assets/fonts/Inter-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter-Medium';
  src: local('Inter Medium'), local('Inter-Medium'),
  url('assets/fonts/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: local('Poppins SemiBold'), local('Poppins-SemiBold'),
  url('assets/fonts/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

html {
  //scroll-behavior: smooth;
}

body {
  font-family: 'Inter-Regular', sans-serif;
  letter-spacing: -0.2px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eceef3;
  position: relative;
  overflow-x: hidden;
  max-width: 100%;
}

i.icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 24px;
  height: 24px;
  width: 24px;
  line-height: 24px;
}

.w-full {
  width: 100% !important;
}

.cursor-pointer {
  cursor: pointer;
}

.modal-open {
  .header-container, footer, .page {
    transition: .3s;
    -webkit-filter: blur(8px);
    -moz-filter: blur(8px);
    -o-filter: blur(8px);
    -ms-filter: blur(8px);
    filter: blur(8px);
  }

  .modal-content {
    border: none;
    border-radius: 24px;
    box-shadow: 0 60px 140px 0 rgba(22, 27, 45, 0.06);
  }
}

h1, h2, h3, h4, h5 {
  font-family: 'Gilroy-Bold', sans-serif;
  font-weight: 500;
  color: #202431;
}

h6 {
  font-family: 'Inter-SemiBold', sans-serif;
}

p, span {
  font-family: 'Inter-Regular', sans-serif;
  margin-bottom: 0;
}

button span {
  font-family: 'Inter-SemiBold', sans-serif;
  letter-spacing: 0;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

li span {
  font-family: 'Inter-SemiBold', sans-serif;
}


.grecaptcha-badge {
  visibility: hidden;
}

a {
  color: #30364a;
  outline: none;
}

.typed-cursor {
  color: $active-blue-text !important;
}

.rnpl-accordion {
  &-panel {
    background-color: $color-white;
    border-radius: 12px;
    box-shadow: 0 0 1px 0 rgba(48, 54, 74, 0.08),
    0 1px 1px 0 rgba(48, 54, 74, 0.08);
    z-index: 2;
    border: none;
    transition: .55s;
    max-height: 48px;
    overflow: hidden;

    &:hover {
      box-shadow: 0 0 2px 0 rgba(48, 54, 74, 0.08),
      0 3px 4px 0 rgba(48, 54, 74, 0.1);
    }

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    .panel-header {
      background-color: transparent;
      height: 48px;
      margin-bottom: 0;
      padding: 0 16px 0 4px;
      border-bottom: none;
      display: flex;
      align-items: center;
      cursor: pointer;
      position: relative;

      .icon-container {
        border-radius: 10px;
        height: 40px;
        width: 40px;
        min-width: 40px;
        background-color: #f2f3f7;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 16px;
        transition: .3s;

        .icon {
          color: #9ca4be;
        }
      }

      .title {
        margin: 0;
        font-family: "Inter-Regular", sans-serif;
        font-size: 16px;
        letter-spacing: -0.2px;
        line-height: 24px;
        color: #5e6681;

        &.device-375 {
          display: none !important;
        }
      }

      .info-rectangle {
        position: absolute;
        right: 74px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 6px;
        height: 24px;
        background-color: #f0f3ff;
        font-family: 'Inter-ExtraBold', sans-serif;
        font-size: 12px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        padding: 0 13.5px;
        color: #4a69fd;
      }

      .indicator-container {
        display: flex;
        align-items: center;

        .icon {
          transition: .3s;
          color: #d2daff;
          position: relative;
          z-index: 0;

          &.icon-checkmark-circle {
            &:before {
              content: '';
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 10px;
              height: 10px;
              background-color: #2e4de3;
              z-index: -1;
            }
          }

          &.icon-close-circle {
            color: #f2f3f7;
            font-size: 32px;
            height: 32px;
            width: 32px;
            line-height: 32px;
            margin-right: -3px;

            &:before {
              content: '';
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 10px;
              height: 10px;
              background-color: #5e6681;
              z-index: -1;
            }
          }

          &.icon-arrow-micro-down {
            color: #30364a;
            border-radius: 6px;

            &:hover {
              background-color: #f2f3f7;
            }
          }
        }

        .border {
          border-radius: 1px;
          height: 32px;
          width: 2px;
          margin: 0 16px;
        }
      }
    }

    .panel-body {
      padding: 4px;

      .title-container {
        display: flex;
        align-items: center;

        .icon {
          margin-right: 24px;
          color: #30364a;
        }

        .title {
          font-family: "Inter-Regular", sans-serif;
          font-size: 14px !important;
          font-weight: 400 !important;
          line-height: 18px !important;
        }
      }

      .info-list {
        &-item {
          padding-left: 8px !important;
          padding-right: 12px !important;
          height: 40px !important;

          .indicator-container {
            display: flex;

            .icon {
              position: relative;
              z-index: 0;
              transition: .3s;
              color: #cdd1df;

              &.icon-checkmark-circle {
                color: #d2daff;

                &:before {
                  content: '';
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  width: 10px;
                  height: 10px;
                  background-color: #2e4de3;
                  z-index: -1;

                }
              }

              &.icon-close-circle {
                color: #f2f3f7;
                font-size: 32px;
                height: 32px;
                width: 32px;
                line-height: 32px;
                margin-right: -4px;

                &:before {
                  content: '';
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  width: 10px;
                  height: 10px;
                  background-color: #5e6681;
                  z-index: -1;
                }
              }
            }
          }

          &:nth-child(odd) {
            background-color: #f8f9fb !important;
          }

          .title-container {
            .icon {
              color: #9ca4be;
            }

            .title {
              font-family: 'Inter-Regular', sans-serif !important;
              font-size: 14px !important;
              font-weight: 400 !important;
              letter-spacing: -0.2px !important;
              line-height: 24px !important;
            }
          }
        }
      }
    }

    &.open {
      max-height: 1000px;
      box-shadow: 0 0 1px 0 rgba(48, 54, 74, 0.08),
                  0 8px 16px 0 rgba(48, 54, 74, 0.1);
    }

    &.selected {
      .panel-header {
        .icon-container {
          background-color: #e0e2eb;

          .icon {
            color: #5e6681;
          }
        }

        .title {
          color: #202431;
        }
      }

      &.open {
        .panel-header .icon-container .icon {
          color: $color-white;
        }
      }
    }
  }
}

.icon-container-violet {
  @include iconContainerGradient(violet);
}

.icon-container-red {
  @include iconContainerGradient(red);
}

.icon-container-yellow {
  @include iconContainerGradient(yellow);
}

.icon-container-green {
  @include iconContainerGradient(green);
}

.icon-container-orange {
  @include iconContainerGradient(orange);
}

.icon-container-purple {
  @include iconContainerGradient(purple);
}

.icon-container-brown {
  @include iconContainerGradient(brown);
}

.icon-container-sky {
  @include iconContainerGradient(sky);
}

.icon-container-mint {
  @include iconContainerGradient(mint);
}

.icon-container {
  .icon {
    color: $color-white;
  }

  &.orange {
    background-color: #ef7748 !important;
  }
  &.yellow {
    background-color: #ffb74d !important;
  }
  &.red {
    background-color: #ef5350 !important;
  }
  &.green {
    background-color: #6bcd50 !important;
  }
  &.violet {
    background-color: #8350ef !important;
  }
  &.purple {
    background-color: #e245d7 !important;
  }
  &.brown {
    background-color: #775148 !important;
  }
  &.sky {
    background-color: #40b9e7 !important;
  }
  &.mint {
    background-color: #35e4b1 !important;
  }
  &.black {
    background-color: $color-main-100 !important;
  }
}

.color-blue-400 {
  color: $color-blue-400 !important;
}
.color-main {
  color: $color-main !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

.rotate-180 {
  transform: rotate(180deg);
}

.box-shadow-none {
  box-shadow: none !important;
}

.rnpl-rang-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .label-container {
    display: flex;
    width: 100%;
    margin-bottom: 18px;
    font-size: 16px;
    height: 24px;
    letter-spacing: -0.2px;
    line-height: 24px;

    .label {
      font-family: "Inter-Medium", sans-serif;
    }

    .count {
      letter-spacing: 0.23px;
    }
  }

  .control-container {
    display: flex;
    width: 100%;
    position: relative;
    height: 28px;

    .free-container {
      height: 4px;
      border-radius: 2px;
      background-color: #49a62f;
      margin-right: 2px;
      flex-shrink: 0;
      //width: 65px;
      width: 65px;
      z-index: 3;
      border-right: 1px solid #e0e2eb;
      position: relative;

      .descr {
        font-family: 'Inter-Regular', sans-serif;
        position: absolute;
        bottom: -4px;
        left: 50%;
        transform: translate(-50%, 100%);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
        color: #49a62f;
        height: 20px;
        white-space: nowrap;
      }
    }

    .rnpl-range.ngx-slider {
      margin-top: 0;

      .ngx-slider-pointer {
        box-shadow: 0 0 2px 0 rgba(48, 54, 74, 0.08),
        0 3px 4px 0 rgba(48, 54, 74, 0.1);
        height: 32px;
        width: 32px;
        background-color: $color-white;

        &:after {
          //background: linear-gradient(-180deg, rgb(219, 221, 232) 0%, rgb(255, 255, 255) 100%);
          //height: 16px;
          //width: 16px;
          //border-radius: 50%;
          //top: 8px;
          //left: 8px;
          content: '';
          width: 8px;
          height: 10px;
          border-radius: 0;
          border-right: 2px solid #cdd1df;
          border-left: 2px solid #cdd1df;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 1;
          box-sizing: content-box;
        }

        &:before {
          content: '';
          position: absolute;
          width: 2px;
          height: 16px;
          border-radius: 1px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: #cdd1df;
          z-index: 2;
        }

        &:hover {
          &:after {
            border-color: #9ca4be;
          }

          &:before {
            background-color: #9ca4be;
          }
        }

        &.ngx-slider-active {
          border: none;
          outline: none;
          cursor: grabbing;

          &:after {
            border-color: #4a69fd;
            background-color: transparent;
            box-shadow: none;
          }

          &:before {
            background-color: #4a69fd;
          }
        }
      }

      .ngx-slider-span.ngx-slider-bar-wrapper {
        &.ngx-slider-selection-bar {
          visibility: visible !important;

          .ngx-slider-selection {
            background-color: $color-blue-400;
            border-radius: 2px;
          }
        }

        &.ngx-slider-full-bar .ngx-slider-bar {
          background: #e0e2eb;
          border-radius: 2px;
        }
      }

      .ngx-slider-floor, .ngx-slider-ceil {
        display: none;
      }

      .ngx-slider-model-value {
        display: none;
        padding: 8px;
        min-width: 34px;
        text-align: center;
        background-color: $color-main;
        font-family: "Inter-Regular", sans-serif;
        font-size: 16px;
        letter-spacing: 0.23px;
        line-height: 18px;
        color: $color-white;
        bottom: -60px;
        border-radius: 8px;

        &:before {
          content: '';
          position: absolute;
          top: -4px;
          left: 50%;
          transform: translateX(-50%);
          width: 0;
          height: 0;
          border: 0 solid transparent;
          border-right-width: 5px;
          border-left-width: 5px;
          border-bottom: 5px solid $color-main;
        }
      }

      //&.caret-left {
      //  .ngx-slider-model-value {
      //    transform: translateX(-20px);
      //  }
      //}
    }
  }
}

.modal.show .modal-dialog {
  margin-top: 24px;
}

.mw-1176 {
  max-width: 1176px;
}
.mw-900 {
  max-width: 900px;
}
.mw-990 {
  max-width: 990px !important;
}
.mw-312 {
  max-width: 312px;
}
.mw-605 {
  max-width: 605px;
}
.mw-676 {
  max-width: 676px;
}
.mw-576 {
  max-width: 576px;
}
.mw-530 {
  max-width: 530px;
}
.mw-428 {
  max-width: 428px;
}
.mw-344 {
  max-width: 344px;
}
.mw-376 {
  max-width: 376px;
}
.mw-414 {
  max-width: 414px;
}

.ml-7 {
  margin-left: 7px;
}

.mh-287 {
  min-height: 287px;
}

.mh-376 {
  max-height: 376px;
}
.mh-390 {
  max-height: 390px;
}
.mh-418 {
  max-height: 418px !important;
}

.object-fir-contain {
  object-fit: contain;
}

.object-fir-cover {
  object-fit: cover !important;
}

.mb--50-percent {
  margin-bottom: -50%;
}

.color-grey {
  color: #9ca4be !important;
}

.hyphens-auto {
  hyphens: auto;
}
.hyphens-none {
  hyphens: none !important;
}

.break-word {
  word-break: break-word;
}

// for blog page

.article-container {
  .article-body {
    .article-section, .blog-title {
      margin-bottom: 40px;

      .section-img {
        border-radius: 16px;
        margin-bottom: 40px;

        img {
          width: 100%;
        }
      }

      h2.section-title {
        font-size: 28px;
        letter-spacing: -0.4px;
        line-height: 34px;
        margin-bottom: 16px;
        color: #202431;
        word-break: break-word;
      }

      h3.section-title {
        font-size: 20px;
        letter-spacing: -0.29px;
        line-height: 26px;
        color: #202431;
        margin-bottom: 16px;
      }

      .section-description, .section-list {
        font-size: 18px;
        font-weight: normal;
        letter-spacing: -0.29px;
        line-height: 32px;
        color: #202431;

        & + .section-description {
          margin-top: 16px;
        }

        & + .section-title {
          margin-top: 24px;
        }

        & + .section-img {
          margin-top: 24px;
        }

        .link {
          color: #4a69fd;
          text-decoration: none;
          transition: .3s;

          &:hover {
            color: #213dc4;
          }
        }
      }

      .section-list {
        margin-top: 16px;

        li {
          padding-left: 24px;
          position: relative;

          span {
            font-family: 'Inter-Regular', sans-serif;

            &.strong {
              font-family: 'Inter-SemiBold', sans-serif;
              font-weight: 600;
            }
          }

          &:not(:last-child) {
            margin-bottom: 16px;
          }

          &:before {
            content: '';
            position: absolute;
            top: 16px;
            left: 0;
            background-color: #4a69fd;
            width: 6px;
            height: 6px;
            border-radius: 50%;
          }
        }
      }

      .list-nested li {
        margin-bottom: 0 !important;

        &:before {
          background-color: #202431
        }
      }

      ol.section-list {
        & > li {
          list-style: decimal;
          padding-left: 0 !important;

          &:before {
            display: none;
          }

          ul {
            padding-left: 16px;

            li {
              padding-left: 0;
              list-style: circle;
              margin-bottom: 0;

              &:before {
                display: none;
              }
            }
          }
        }
      }

      .section-info-block.white {
        background-color: $color-white;
        border-radius: 16px;
        box-shadow: 0 20px 60px -20px rgba(10, 54, 164, 0.2);
        padding: 32px 40px;
        margin-top: 40px;

        .title-container {
          display: flex;
          align-items: center;
          margin-bottom: 8px;

          img {
            width: 36px;
            margin-right: 12px;
          }

          .title {
            font-size: 28px;
            letter-spacing: -0.4px;
            line-height: 34px;
            margin-bottom: 0;
          }
        }

        .description {
          font-size: 18px;
          letter-spacing: -0.26px;
          line-height: 31px;
          color: #202431;
        }

        &.text-center {
          text-align: center;
        }

        &.mt-16 {
          margin-top: 16px;
        }
      }
    }
  }

  .author-info {
    display: flex;
    align-items: center;

    .avatar-container {
      height: 64px;
      width: 64px;
      min-width: 64px;
      border-radius: 6px;
      margin-right: 16px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .name-container {
      display: flex;
      flex-direction: column;

      .additional-info {
        font-family: 'Rubik-Medium', sans-serif;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        color: #9ca4be;
      }

      .link {
        text-decoration: none;
      }

      .name {
        font-family: 'Gilroy-Bold', sans-serif;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: -0.29px;
        line-height: 24px;
        color: #202431;
        transition: .3s;

        &:hover {
          color: #4a69fd;
        }
      }

      .position {
        font-size: 14px;
        font-weight: normal;
        letter-spacing: -0.2px;
        line-height: 24px;
        color: #5e6681;
      }
    }
  }
}

.strong {
  font-family: 'Inter-SemiBold', sans-serif;
}

.ng-select-container {
  box-shadow: none;
}

.font-italic {
  font-style: italic;
}

.color-green-400 {
  color: #6BCD50 !important;
}

ngb-modal-window.modal {
  backdrop-filter: blur(5px);
}

@media (max-width: 961px) {
  .article-container {
    .article-anchor-container {
      display: none;
    }

    .article-body {
      margin-left: 0;
    }
  }
}

@media (max-width: 481px ) {
  select:focus,
  textarea:focus,
  input:focus,
  input,
  textarea,
  select,
  input[type="text"] {
    font-size: 16px !important;
  }

  .rnpl-accordion-panel {
    .panel-header .title {
      &.device-depended:not(.device-375) {
        display: none !important;
      }

      &.device-375 {
        display: inline-flex !important;
        flex-direction: row !important;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  .article-container {
    padding: 0 12px;

    .article-body {
      margin-bottom: 24px;

      .article-section, .blog-title {
        margin-bottom: 24px;

        .section-title {
          font-size: 20px;
          letter-spacing: -0.29px;
          line-height: 26px;
        }

        .section-description, .section-list {
          font-size: 14px;
          letter-spacing: -0.2px;
          line-height: 28px;

          & + .section-description {
            margin-top: 8px;
          }
        }

        .section-list {
          margin-top: 8px;
          margin-bottom: 24px;

          li {
            padding-left: 14px;
            position: relative;

            &:not(:last-child) {
              margin-bottom: 16px;
            }

            &:before {
              top: 11px;
            }
          }
        }

        .section-info-block.white {
          padding: 16px;

          .title-container {
            margin-bottom: 4px;

            .title {
              font-size: 20px;
              letter-spacing: -0.29px;
              line-height: 26px;
            }
          }

          .description {
            font-size: 14px;
            letter-spacing: -0.2px;
            line-height: 28px;
          }

          &.mt-16 {
            margin-top: 8px;
          }
        }

        .author-info {
          .avatar-container {
            height: 56px;
            width: 56px;
            min-width: 56px;
            border-radius: 12px;
            margin-right: 12px;
          }

          .name-container {
            .name {
              font-size: 16px;
              letter-spacing: -0.23px;
              line-height: 22px;
            }
          }
        }
      }
    }
  }

  .remove-lb-sm-screen {
    br {
      display: none !important;
    }
  }

  .fix-mobile-text-indent {
    & > br {
      display: none;
    }
  }
}
