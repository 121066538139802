ngb-modal-window {
  .modal-dialog {
    width: 100%;
    max-width: 676px;
  }
}


@media (max-width: 481px) {
  .modal-dialog {
    margin: 0 !important;
    margin-top: 0 !important;
    padding: 4px 8px;
  }
}
