/**
 * @license
 * MyFonts Webfont Build ID 3922813, 2020-07-07T04:33:08-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: Gilroy-Bold by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/bold/
 * Copyright: Copyright &amp;#x00A9; 2016 by Radomir Tinkov. All rights reserved.
 *
 *
 *
 * © 2020 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/3bdb7d");

@font-face {
  font-family: 'Gilroy-Bold';
  font-display: swap;
  src: url('../fonts/webFonts/Gilroy-Bold/font.woff2') format('woff2'), url('../fonts/webFonts/Gilroy-Bold/font.woff') format('woff');
}

