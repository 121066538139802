//colors
$color-white: #ffffff;
$color-black: #000000;
$color-blue: #4a69fd;
$color-green: #37973e;
$color-red: #ef5350;

$color-main: #202431;
$color-violet: #8259e3;
$color-grey: #e0e2eb;
$color-grey-dark: #30364b;

//text-color
$color-text-blue: #4748ff;
$color-text-purple: #e245d7;
$color-text-aqua: #40b9e7;
$color-text-green: #6bcd50;
$color-text-yellow: #f2a33e;
$color-text-orange: #ee7545;
$color-text-grey: #8a8f9d;
$color-text-grey-light: #e6eaff;
$color-text-grey-600: #99a1b8;

// Blue colors
/* #4A69FD */
$color-blue-400: $color-blue;
$color-blue-700: #4867fc;
$color-blue-500: #3857eb;
$color-blue-600: #2e4de3;
$color-blue-200: #D2DAFF;
$color-blue-300: #96A8FE;

//Grey colors
/* #e0e2eb */ $color-grey-600: #b5bbce;
/* #e0e2eb */ $color-grey-400: $color-grey;
/* #eceef3 */ $color-grey-300: #eceef3;
/* #eef1f7 */ $color-grey-200: #eef1f7;
/* #F2F3F7 */ $color-grey-100: #f2f3f7;

// Main colors
/* #202431 */ $color-main-600: $color-main;
/* #282D3D */ $color-main-500: lighten($color-main, 4%);
/* #30364A */ $color-main-400: lighten($color-main, 8%);
/* #3E465F */ $color-main-300: lighten($color-main, 15%);
/* #5E6681 */ $color-main-200: lighten(desaturate($color-main, 5%), 28%);
/* #9CA4BE */ $color-main-100: lighten($color-main, 52%);

//gradients fro btn
$gradient-btn-blue: linear-gradient(-180deg, rgb(74, 105, 253) 0%, rgb(46, 77, 227) 100%);

$gradient-btn-white: linear-gradient(-180deg, rgb(255, 255, 255) 0%, rgb(245, 246, 249) 96%, rgb(238, 241, 250) 100%);

$gradient-btn-green: linear-gradient(-180deg, rgb(107, 205, 80) 0%, rgb(73, 166, 47) 100%);

//gradients for action-card
$gradient-action-card-blue: linear-gradient(-135deg, #4a69fd 0%, #5a76ff 100%);
$gradient-action-card-black: linear-gradient(-135deg, rgba(32, 36, 48, 0) 0%, rgb(47, 52, 67) 100%);
$gradient-action-card-white: linear-gradient(
  -135deg,
  rgb(255, 255, 255) 0%,
  rgb(247, 248, 251) 100%
);
$gradient-action-card-green: linear-gradient(
  -135deg,
  rgb(86, 189, 93) 0%,
  rgb(98, 204, 105) 100%
);

$bg-color-grey: #f8f9fb;

//colors for action-card
$background-action-card-black: #202431;
$background-action-card-pink: #ff4a7d;
$background-action-card-sky: #4ae8ff;
$background-action-card-sky-dark: #008cff;
$background-action-card-orange: #ffba4a;
$background-action-card-violet: #a86ab4;
$background-action-card-red: #fe2f2f;
$background-action-card-green: #56bd5d;

//colors for message component
$bg-color-message-blue: #425efc;
$message-bg-color: #f4f5f9;

//colors for to info rectangle
$text-color-pink: #e950b4;
$text-color-violet: #c455fb;
$text-color-sky: #2da6f4;
$text-color-orange: #e98850;
$text-color-purple: #cb508e;
$text-color-mint: #22aaac;
$text-color-green: #5eac22;

//colors for slider component
$carousel-indicators-bg-color: rgb(181, 187, 206);
$carousel-nav-bg-color: rgb(181, 187, 206);

//colors for tooltip component
$tooltip-dark-grey: #5e6682;
$tooltip-light-blue: #d2daff;
$tooltip-dark-blue: #4a69fd;

//colors for label-box component
$label-green-light: #c6ecbb;
$label-green-dark: #49a62f;

//colors for form elements
$inactive-gray: #cdd1df;
$active-blue: #4a69fd;
$active-blue-text: #4565fd;
$bg-yellow: #fbd955;
$txt-braun: #8f5625;
$permition-color: #3f8e29;

//colors for table
$table-color-grey: #f8f9fb;


//colors for input
$color-border-red: #fbcfce;
$color-danger: #c71612;
$color-border-hover: #cdd1df;
$color-active-input: #30364a;
$color-border-green: #c6ecbb;
$color-success: #3f8e29;
$color-border-yellow: #ffe4bd;
$color-warning: #e68900;

//app module color
$color-module-accounting: #d6b27e;
$color-module-accounting-border: #C69D6A;

$color-module-trade: #ecbf5f;
$color-module-trade-border: #DCAA42;

$color-module-products: #878ef9;
$color-module-products-border: #7780E7;

$color-module-warehouse: #ff826f;
$color-module-warehouse-border: #F76D5D;

$color-module-partners: #2bd0e6;
$color-module-partners-border: #00BED2;

$color-module-team: #32cd87;
$color-module-team-border: #00BB73;

$color-module-analytics: #3691f3;
$color-module-analytics-border: #0084E2;

$color-module-shop: #a157ff;
$color-module-shop-border: #9E4BF1;

//dropdown disabled bg
$disabled-dropdown-color: #1B1F2A;

//icon color
$icon-color-main: #30364a;

//border radius
$button-radius: 12px;


//gradients deg
$page-gradient-deg: -28.5deg;

//buttons colors
$blue-btn-default: #459ABF;
$blue-btn-hover: #0E81A6;
$blue-btn-active: #152faa;
//
//$blue-btn-default: #4a69fd;
//$blue-btn-hover: #213dc4;
//$blue-btn-active: #152faa;

$white-btn-default: #ffffff;
$white-btn-hover: #f8f9fb;
$white-btn-active: #e0e2eb;

$black-btn-default: #202431;
$black-btn-hover: #3e465f;
$black-btn-active: #5e6681;

$dark-btn-default: #30364a;
$dark-btn-hover: #3e465f;
$dark-btn-active: #3e465f;

$green-btn-default: #49A62F;
$green-btn-hover: #3F8E29;
$green-btn-active: #266415;

$grey-btn-default: #f2f3f7;
$grey-btn-hover: #f2f3f7;
$grey-btn-active: #f2f3f7;


$blur-head: saturate(150%) blur(40px);
$background-color-alpha-head: rgba(236,238,243,.60);
